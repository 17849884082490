import React from "react";
import { PageLayout } from "../components/PageLayout";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ProjectList } from "../components/ProjectList.jsx";
import { pageContent } from "src/page-content";

console.log(pageContent)
export const Homepage = () => {
  const reversedProjects = pageContent.reverse();
  const years = ["2024","2023", "2022", "2021","2020"];

  return (
    <PageLayout>
      <div>
        <div className="container" style={{ marginTop: "4em" }}>
          <div style={{ width: "250px", margin: "0 auto" }}>
            <a href="https://thecity.nyc/" aria-label="THE CITY">
              <Logo />
            </a>
          </div>
          {/* <h1 className="city-headline">THE CITY</h1> */}
          <div style={{ marginTop: "15px" }} className="headline">
            Data & Products Team
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "4em",
          marginBottom: "1em",
        }}
      >

        <div className="bird-container bird-container-one">
          <div className="bird bird-one"></div>
        </div>
        <div className="bird-container bird-container-two">
          <div className="bird bird-two"></div>
        </div>
        <div className="bird-container bird-container-three">
          <div className="bird bird-three"></div>
        </div>
        <div className="bird-container bird-container-four">
          <div className="bird bird-four"></div>
        </div>
      </div>

      <div className="container" style={{}}>
        <div
          style={{
            height: "4em",
            display: "flex",
            alignItems: "center",
            marginBottom: "-3em",
          }}
        >
          <h2 style={{ fontSize: "2.4em", marginLeft: "5px" }}>
            PROJECTS TIMELINE:
          </h2>
        </div>

        {years.map((year) => (
          <ProjectList year={year} projects={reversedProjects} />
        ))}
      </div>
    </PageLayout>
  );
};

// https://codepen.io/matchboxhero/pen/RLebOY
// https://codepen.io/imdoug/pen/vYZNoYr


export const ProjectList = ({projects, year}) => {
    
    return(
        <div style={{borderTop:"5px solid #fdbf2a", marginTop:"3em"}}>
        <h2 style={{fontSize:"1.9em"}}>{year}</h2>
        {projects
        .filter(proj => proj.date.endsWith(year[3]))
        .map((proj)=>(<div className="copy"><a href={proj.link} target="_blank" rel="noopener noreferrer">{proj.title}</a></div>))
        }
        </div>
    )
}

export default ProjectList